export function readInfo(info) {
  let data = !localStorage.getItem("readInfo") ? [] : JSON.parse(localStorage.getItem("readInfo"));
  if(data.length <= 0 ){
    data.unshift(info);
  }else if (data.findIndex((target) => target.id === info.id) == -1) {
    data.unshift(info);
  }

  localStorage.setItem("readInfo", JSON.stringify(data));
  let dataInfo = JSON.parse(localStorage.getItem("readInfo"));
  if (dataInfo.length > 3) {
    dataInfo.pop();
    localStorage.setItem("readInfo", JSON.stringify(dataInfo));
  }

  return dataInfo;
}

export function data(index) {
  let data = !localStorage.getItem("readInfo") ? [] : JSON.parse(localStorage.getItem("readInfo"));
  if(data.length <= 0 ){
    data.unshift(info);
  }else if (data.findIndex((target) => target.id === info.id) == -1) {
    data.unshift(info);
  }

  localStorage.setItem("readInfo", JSON.stringify(data));
  let dataInfo = JSON.parse(localStorage.getItem("readInfo"));
  if (dataInfo.length > 3) {
    dataInfo.pop();
    localStorage.setItem("readInfo", JSON.stringify(dataInfo));
  }

  return dataInfo;
}
