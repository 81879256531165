<template>
  <div class="main">
    <Tobs></Tobs>
    <img class="headImg" src="@/assets/jobInformation/bgc.png" alt="" />
    <div class="content c">
      <div class="lfetBox">
        <div class="personInfo">
          <!-- <img
            class="personAvatar"
            src="@/assets/jobInformation/4.png"
            alt=""
          /> -->
          <img
            class="personAvatar"
            :src="avatar ? $imgUrl(avatar): srcImgUrl1"
            :onerror="$global.srcImgUrl"
            alt=""
          />
          <div class="personInfoRight">
            <div class="left">
              <div class="personName">
                <span class="peopleName">{{ $processName(jobInfo.name) }}</span>
                <span
                  style="color: #ff8d19; font-size: 12px; margin-right: 10px"
                >
                  <i class="iconfont icon-shiming"></i>
                  已实名
                </span>
                <span
                  v-if="
                    degreeId ||
                    qualificationId ||
                    titleId
                  "
                  style="color: #00c750; font-size: 12px; margin-right: 10px"
                >
                  <i class="iconfont icon-fabuzhengshu"></i>
                  技能认证
                </span>
                <span v-if="resume" style="color: #2B95FF; font-size: 12px; margin-right: 10px">
                  <i class="el-icon-tickets" style="font-size:16px"></i>
                  简历已填写
                </span>
              </div>

              <div>
                <div class="personSexBox personColor">
                  <span>{{ jobInfo.sex == 1 ? '男' : '女' }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ jobInfo.age }}岁</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ jobInfo.nation }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ tel }}</span>
                  <el-divider direction="vertical"></el-divider>
                  <span>{{
                    jobInfo.title ? getJobUserType(jobInfo.title) : ''
                  }}</span>
                </div>
                <!-- <div class="workType personColor">
                  <span
                    >工种：{{
                      jobInfo.expectPost
                        ? getExpectPostForId(tradeTypeList, jobInfo.expectPost)
                        : ''
                    }}</span
                  >
                </div> -->
              </div>
            </div>

            <div class="right">
              <div class="ReleaseTimeBox">
                <span class="time"
                  >发布时间：{{
                    jobInfo.auditTime
                      ? jobInfo.auditTime.substring(
                          0,
                          jobInfo.auditTime.indexOf(' ')
                        )
                      : ''
                  }}</span
                >
                <div class="city personColor">
                  <i
                    class="iconfont icon-dingxiang"
                    style="margin-right: 10px; font-size: 18px; color: #1592ea"
                  ></i>
                  <span
                    >{{
                      jobInfo.province
                        ? getProvinceForId(jobInfo.province)
                        : ''
                    }}·{{
                      jobInfo.city ? getCityForId(jobInfo.city) : ''
                    }}</span
                  >
                </div>
              </div>
              <!-- <el-button type="primary" @click="dialogVisible = true" size="medium">获取联系方式</el-button> -->
              <!-- <el-button type="primary" @click="getPhone" size="medium">获取联系方式</el-button> -->

              <!-- <el-button plain size="medium" icon="el-icon-document" @click="getResume" v-if="orgId">查看简历</el-button> -->
            </div>
          </div>
        </div>

        <div class="divider">
          <el-divider></el-divider>
        </div>

        <div class="projectExperience">
          <div class="listTitle">
            <span>工作经历</span>
            <div class="orange"></div>
          </div>
          <!-- <div class="projectExperienceItem">
            <div class="corporateName mb">顺康基建武汉分公司</div>
            <div class="position mb">技术员</div>
            <div class="jobDescription mb">
              <span>工作描述：</span>
              <span
                >负责项目图纸的审核，工地现场考察、勘察、放线、测量、测绘，现场施工协调管理</span
              >
            </div>
          </div> -->
          <span style="font-size: 14px">{{ jobInfo.jobInfo }}</span>
        </div>

        <div class="selfEvaluation">
          <div class="listTitle">
            <span>自我评价</span>
            <div class="orange"></div>
          </div>
          <span style="font-size: 14px">{{ jobInfo.myIntro }}</span>
        </div>

        <div class="skillCertificate">
          <div class="listTitle">
            <span>技能证书</span>
            <div class="orange"></div>
          </div>
          <div class="skillCertificateBox">
            <div class="skillCertificateItem">
              <span>学历证书</span>
              <img
                v-if="degreeId"
                :src="$imgUrl(degreeId)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="skillCertificateItem">
              <span>资格证书</span>
              <img
                v-if="qualificationId"
                :src="$imgUrl(qualificationId)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="skillCertificateItem">
              <span>职称证书</span>
              <img
                v-if="titleId"
                :src="$imgUrl(titleId)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
          </div>
        </div>

        <div class="location">
          <div class="listTitle">
            <span>所在地</span>
            <div class="orange"></div>
          </div>
          <div class="locationBox">
            <div id="container">
              <el-amap
                v-if="markerPosition.length > 0"
                class="amap-box"
                :vid="'amap-vue'"
                :center="markerPosition"
              >
                <el-amap-marker :position="markerPosition"></el-amap-marker>
                <el-amap-text
                      :text=" `${ jobInfo.province
                        ? getProvinceForId(jobInfo.province)
                        : ''}·${jobInfo.city ? getCityForId(jobInfo.city) : ''}` "
                      :offset="offset"
                    ></el-amap-text>
              </el-amap>
            </div>
          </div>
        </div>
      </div>
      <div class="listRight">
        <div class="seeList">
          <div class="title">看过的人才</div>
          <div class="peopleList">
            <div class="peopleItem" v-for="(item, index) in readInfo" :key="index" @click="toDetails(item)">
                <img :src="item.avatar ? item.avatar : srcImgUrl1" :onerror="$global.srcImgUrl" alt="" />
                <div class="peopleInfo">
                  <div class="name">
                    <span>{{ item.name ? $processName(item.name) : '' }}</span>
                    <span>{{ item.exp ? getExp(item.exp) : '' }}</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">{{
                      item.expectPost
                        ? getExpectPostForId(tradeTypeList, item.expectPost)
                        : ''
                    }}</el-tag>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="recommend">
          <div class="title">给工人推荐</div>
          <div class="peopleList">
            <div class="peopleItem" v-for="(item, index) in recommendList" :key="index" style="cursor: pointer;" @click="toDatumInfo(item)">
              <!-- <img src="@/assets/jobInformation/4.png" alt="" /> -->
              <div class="peopleInfo">
                  <div class="name" style="margin-bottom:12px;">
                    <span>{{ item.name }}</span>
                    <span style="color: #ff8d19;">{{ getPay(item.pay) }}</span>
                  </div>
                  <div class="tag">
                    <el-tag size="small">{{ item.city ? $dictInfo.getCityForId(item.city) :'' }} | {{ getExp(item.exp) }} |
                            {{ getEdu(item.edu) }}</el-tag>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foots></Foots>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>{{tips}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirm(confirmType)">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看简历 -->
    <SeeResume :seeFlag="seeFlag" :resume="resume" :subEquity="subEquity" @change="changeFlag($event)" v-if="seeFlag"></SeeResume>
  </div>
</template>

<script>
import Tobs from '@/components/hometitle/hometitle.vue'
import Foots from '@/components/foot/foot.vue'
import { jobInfoDetail } from '@/api/jobInformation/jobInformation'
import { jobPostType, jobPostSearch } from '@/api/position/position'
// import { getLoginUser } from '@/api/login/login'
import { detailResume } from '@/api/personalCenter/personalCenter'
import { getAreaById } from '@/api/index/index'
import { subtractEquityNum,oneEquityNum,oneEquityLogsNum } from '@/api/equity/equity.js'
import SeeResume from "@/components/seeResume/seeResume.vue";
import { readInfo } from '@/utils/readInfo.js'
export default {
  name: '',
  data() {
    return {
      srcImgUrl1: require("@/assets/home/people.png"),
      dict: [],
      offset: [0, -50],
      jobVipEquity: [], //权益id
      jobUserType: [], //人员构成
      jobInfoId: '', //求职信息id
      degreeId: '',
      qualificationId: '',
      titleId: '',
      jobInfo: {}, //求职信息详情
      tradeTypeList: [], //工种
      showProvinceList: [],
      showCityList: [],
      resume: [],
      tel: '',
      avatar: '',
      markerPosition: [],
      userId: '',
      orgId: '',
      jobVipEquityId: '',
      tips: '',
      confirmType: '',
      dialogVisible: false,
      seeFlag: false,
      subEquity: false,//是否扣除过权益
      readInfo:'',
      exp:[],
      jobPay:[],
      jobEdu:[],
      recommendList:[]
    }
  },
  watch: {},
  props: {},
  components: {
    Tobs,
    Foots,
    SeeResume
  },
  computed: {},
  created() {
    if(localStorage.getItem("readInfo")){
      this.readInfo = JSON.parse(localStorage.getItem("readInfo"))
    }
    this.userId = localStorage.getItem('userId')
    this.orgId = localStorage.getItem('orgId')
    this.dict = JSON.parse(localStorage.getItem('dict'))
    this.exp = this.dict.filter(i => i.code == 'job_exp')[0].children
    this.jobVipEquity = this.dict.filter(
      i => i.code == 'job_vip_equity'
    )[0].children
    this.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 15)[0].name
    this.jobInfoId = this.$route.query.id
    this.degreeId = this.$route.query.degreeId
    this.qualificationId = this.$route.query.qualificationId
    this.titleId = this.$route.query.titleId
    this.jobUserType = this.dict.filter(
      i => i.code == 'job_user_type'
    )[0].children
    this.showProvinceList = JSON.parse(localStorage.getItem('provincialList'))
    this.showCityList = JSON.parse(localStorage.getItem('cityList'))
    this.jobPay = this.dict.filter(i => i.code == 'job_pay')[0].children
    this.jobEdu = this.dict.filter(i => i.code == 'job_edu')[0].children
    this.getJobTradeType()
    // this._getLoginUser()
    this.getJobInfoDetail()
    this.recommend()
    // if(this.orgId) {
    //   this._oneEquityLogsNum()
    // }
  },
  mounted() {
    this.clearMapStore()
  },
  methods: {
    clearMapStore(){
    Object.keys(localStorage).forEach(item =>
         item.indexOf('_AMap') !== -1 ? localStorage.removeItem(item) : ''
     );
  },
    async recommend(){
      let obj = {
        grounding: 'Y',
        auditStatus: 2,
        pageNo: 1,
        pageSize: 10
      }
      let res = await jobPostSearch(obj)
      let data =  res ? res.data.rows.sort((a, b) => Math.random() > 0.5 ? 1 : -1) : [];
      this.recommendList = data
    },
    // 查看职位详情
    toDatumInfo(item) {
      this.$router.push({
        path: '/datum',
        query: { id: item.id },
      })
    },
    // _getLoginUser() {
    //   getLoginUser().then(res => {
    //     this.tel = res.data.tel.replace(res.data.tel.substring(3, 7), '****')
    //   })
    // },
    // 获取简历
    getDetailResume(id) {
      detailResume({ JobUserId: id }).then(res => {
        console.log(res.data)
        this.resume = res.data
        this.avatar = res.data.avatar
        this.tel = res.data.tel.replace(res.data.tel.substring(3, 7), '****')
        // 获取简历之后再查询是否扣除过权益
        if(this.resume.id && this.userId) {
          if(this.userId === this.resume.jobUserId){
            this.tel = this.resume.tel
          }
          this._oneEquityLogsNum()
        }
      })
    },
    // 工种
    getJobTradeType() {
      jobPostType({ code: 'trade_type' }).then(res => {
        this.tradeTypeList = res.data[0].children
      })
    },
    // 回显工种
    getExpectPostForId(list, id) {
      var title = ''
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title
          break
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.getExpectPostForId(list[i].children, id)
          if (title) return title
        }
      }
      console.log(title)
      return title
    },
    // 显示工作经验
    getExp(val) {
      return this.exp.filter(i => i.code == val)[0].name
    },
    // 显示工资
    getPay(code) {
      return this.jobPay.filter(i => i.code == code)[0].name
    },
    // 显示学历
    getEdu(val) {
      return this.jobEdu.filter(i => i.code == val)[0].name
    },
    // 回显省份
    getProvinceForId(id) {
      return this.showProvinceList.filter(i => i.PROVINCE_CODE == id)[0]
        .SHORT_NAME
    },
    // 回显城市
    getCityForId(id) {
      return this.showCityList.filter(i => i.CITY_CODE == id)[0].SHORT_NAME
    },
    getJobInfoDetail() {
      jobInfoDetail({ id: this.jobInfoId }).then(res => {
        this.jobInfo = res.data
        getAreaById({ AREA_CODE: res.data.area }).then(res1 => {
          if (res1.success) {
            this.markerPosition = [res1.data.LNG, res1.data.LAT]
          }
        })
        if (this.jobInfo.jobUserId) {
          this.getDetailResume(this.jobInfo.jobUserId)
        }
      })
    },
    getJobUserType(id) {
      return this.jobUserType.filter(i => i.code == id)[0].name
    },

    // 查询是否扣除过权益
    _oneEquityLogsNum() {
      let obj = {
        jobOrgId: this.orgId,
        // jobVipEquityId: this.jobVipEquityId,
        index: 1,
        operationId: this.resume.id,
        userType: 2
      }
      oneEquityLogsNum(obj).then(res => {
        console.log(res,'是否扣除过权益');
        if(res.success && res.data.status) {
          console.log('扣除过权益');
          this.tel = this.resume.tel
          console.log(this.resume.tel);
          this.subEquity = true
        }
      })
    },

    // 确定对话框
    confirm(confirmType) {
      console.log(confirmType,'confirmType');
      // 1:前往充值 2:花权益购买
      if(confirmType == 1) {
        this.$router.push('/memberCenter')
      } else if (confirmType == 2) {
        this._subtractEquityNum()
      } else if(confirmType == '') {
        this.dialogVisible = false
      }
    },

    // 取消对话框
    dialogCancel() {
      this.dialogVisible = false
      this.confirmType = ''
    },

    // 获取电话号码
    getPhone() {
      if(!this.userId) {
        this.$confirm('请先登录!', '', {
          confirmButtonText: '登录',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
         this.$router.push('/login')
        }).catch(() => {});
        return
      }
      if(!this.orgId) {
        this.dialogVisible = true
        this.tips = '暂无相关权益'
        this.confirmType = ''
        return
      }
      this.jobVipEquityId = this.jobVipEquity.filter(i => i.code == 15)[0].name
      // this.getEquityNum(this.orgId,this.jobVipEquityId)
      this.getEquityNum(this.orgId,1)
    },

    toDetails(item) {
      this.readInfo = readInfo(item)

      this.$router.push({
        path: '/jobInformationDetails',
        query: {
          id: item.id,
          degreeId: item.degree_certificate,
          qualificationId: item.qualification_certificate,
          titleId: item.title_certificate,
        },
      })
      this.$router.go(0)
    },
    // 获取权益次数
    getEquityNum(jobOrgId,jobVipEquityId) {
      let obj = {
        jobOrgId: jobOrgId,
        // jobVipEquityId: jobVipEquityId,
        index: jobVipEquityId,
        userType: 2
      }
      oneEquityNum(obj).then(res => {
        console.log(res,'权益次数');
        if(res.success) {
          this.dialogVisible = true
          if(res.data == 0) {
            this.tips = '当前权益次数不足，是否前往充值？'
            this.confirmType = 1
          } else {
            this.tips = '查看联系方式将扣除您的相关权益，是否查看？'
            this.confirmType = 2
          }
        }
      })
    },

    // 扣除权益次数
    _subtractEquityNum() {
      let obj = {
        jobOrgId: this.orgId,
        // jobVipEquityId: this.jobVipEquityId,
        index: 1,
        subtractNum: 1,
        operationId: this.resume.id
      }
      console.log(obj,'obj');
      subtractEquityNum(obj).then(res => {
        if(res.success) {
          this.dialogVisible = false
          this.tel = this.resume.tel
          this.$message.success('购买成功')
        } else {
          this.dialogVisible = false
          this.$message.error('购买失败，请重试')
        }
      })
    },
    // 查看简历
    getResume() {
      this.seeFlag = true
    },
    changeFlag(value) {
      this.seeFlag = value
    },
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}

.personColor {
  color: #666;
}

.mb {
  margin-bottom: 10px;
}

.divider {
  padding: 0 30px;
  .el-divider {
    margin: 0;
  }
}

.headImg {
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin-top: 80px;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: -64px;
  margin-bottom: 100px;
}

.listTitle {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  span {
    font-size: 18px;
    font-weight: bold;
  }
  .orange {
    width: 40px;
    height: 4px;
    background-color: #ff8d19;
    margin-top: 6px;
  }
}

.lfetBox {
  width: 900px;
  padding: 20px;
  background-color: #fff;
  padding: 10px 0 40px 0;
  .personInfo {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    .personAvatar {
      width: 140px;
      height: 140px;
      object-fit: contain;
    }
    .personInfoRight {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 30px;
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .personName {
          .peopleName {
            margin-right: 10px;
            font-size: 28px;
            font-weight: bold;
          }
        }
        .personSexBox {
          margin-bottom: 20px;
        }
        .workType {
          margin-bottom: 10px;
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .ReleaseTimeBox {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .time {
            color: #666;
            font-size: 14px;
          }
          .city {
            margin-top: 10px;
            display: flex;
            align-items: center;
          }
        }

        .contact {
          width: 140px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background-color: #21a3ff;
          color: #fff;
          font-size: 18px;
          border-radius: 4px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.listRight {
  width: 282px;
  .seeList,
  .recommend {
    background-color: #fff;
    margin-bottom: 16px;
    padding-bottom: 10px;
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
    .peopleList {
      padding: 0 20px 0 24px;
      .peopleItem:last-child {
        border-bottom: none;
      }
      .peopleItem {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #f2f2f2;
        img {
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }
        .peopleInfo {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .name {
            display: flex;
            justify-content: space-between;
            span:nth-child(1) {
              font-size: 14px;
            }
            span:nth-child(2) {
              font-size: 12px;
              color: #666;
            }
          }
          .tag {
            font-size: 12px;
          }
        }
      }
    }
  }
  .recommend {
    background-color: #fff;
    .title {
      height: 48px;
      font-size: 14px;
      color: #029aff;
      line-height: 48px;
      padding: 0 20px 0 24px;
      border-bottom: 1px solid #e8e8e8;
    }
  }
}

.projectExperience {
  padding: 0 30px;
  .projectExperienceItem {
    .corporateName {
      font-size: 18px;
    }
    .position {
    }
    .jobDescription {
      display: flex;
      span {
        font-size: 14px;
      }
      span:nth-child(1) {
        width: 80px;
      }
      span:nth-child(2) {
        flex: 1;
      }
    }
  }
}

.selfEvaluation {
  padding: 0 30px;
}

.skillCertificate {
  padding: 0 30px;
  .skillCertificateBox {
    display: flex;
    flex-wrap: wrap;
    .skillCertificateItem {
      display: flex;
      flex-direction: column;
      margin-right: 40px;
      span {
        font-size: 14px;
        color: #666;
      }
      img {
        margin-top: 10px;
        width: 140px;
        height: 88px;
        object-fit: contain;
      }
    }
  }
}

.location {
  padding: 0 30px;
  .locationBox {
    height: 330px;
    #container {
      // width: 841px;
      // height: 332px;
      // margin-top: 11px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
